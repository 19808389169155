import React from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  navyBlue,
  mainRed,
  mainWhite,
  aku,
  innerWidth,
  transHover,
  gotham,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .description {
    color: ${navyBlue};
    font-family: ${gotham};
    font-size: 1.15rem;
    margin-top: 22px;
    @media ${screen.small} {
      font-size: 1.25rem;
      margin-top: 32px;
    }
  }

  a {
    color: ${mainWhite};
    background: ${navyBlue};
    display: table;
    margin-top: 25px;
    font-family: ${gotham};
    font-size: 1rem;
    padding: 11px 16px;
    transition: ${transHover};
    @media ${screen.small} {
      padding: 13px 20px;
      font-size: 1.1rem;
      margin-top: 50px;
    }
    &:hover {
      background: ${mainRed};
    }
  }
`;

const ThanksPage = () => (
  <Layout>
    <SEO title="Enquiry Received | TEN ALPHAS" />
    <Wrapper>
      <h2 className="heading">
        <Arrow />
        THANK YOU
      </h2>
      <p className="description">
        Your enquiry has been received, we will get back to you as soon as we
        can.
      </p>
      <Link to="/">Back To Home Page</Link>
    </Wrapper>
  </Layout>
);

export default ThanksPage;
